import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import { MultiSelect } from 'components/common/multi-select'
import { regionsVar } from 'config/apollo/analytics-report/variables'
import { ReactComponent as RegionsMap } from 'images/analytics-report/regions-map.svg'
import classNames from 'classnames/bind'
import styles from './RegionsDropdown.module.scss'

const cx = classNames.bind(styles)

const regions = [
  'East Midlands',
  'East of England',
  'London',
  'North East',
  'North West',
  'South East',
  'South West',
  'West Midlands',
  'Yorkshire and The Humber',
  'Scotland',
  'Wales'
]

const RegionsDropdown = () => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(regionsVar)
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isModalOpen])

  const handleItemClick = (regions) => {
    setSelectedItem(regions)
    regionsVar(regions)
  }

  const handleDropdownClick = () => {
    return null
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleDeselectAll = () => {
    regionsVar('')
  }

  const handleSelectAll = () => {
    regionsVar(regions)
  }

  return (
    <>
      <MultiSelect
      placeholder={upperFirstOnly(
        t('widgets.analytics.noRegionsSelected')
      )}
      allItemsText={upperFirstOnly(
        t('widgets.analytics.allBenchmarkRegions')
      )}
      items={regions}
      value={selectedItem}
      onValueSelect={handleItemClick}
      onDropdownClick={handleDropdownClick}
      maxHeight={'228px'}
      selectButtons={true}
      viewSearch={true}
      customHandleSelectAll={handleSelectAll}
      customHandleDeselectAll={handleDeselectAll}
      dataTestId={'regions-dropdown'}
    >
      <div className={cx('view-map')} onClick={handleOpenModal} >
        {t(upperFirstOnly(t('widgets.analytics.viewMap')))}
      </div>
      </MultiSelect>

      {isModalOpen && (
        <div className={cx('modal-overlay-style')} onClick={handleCloseModal}>
          <div className={cx('modal-content-style')} onClick={e => e.stopPropagation()}>
          <div className={cx('header-container')}>
            <h2 className={cx('modal-title')}>{(t('widgets.analytics.mapOfUK'))}</h2>
            <hr className={cx('title-divider')} />
          </div>
            <button onClick={handleCloseModal} className={cx('close-button-style')}>
              &times;
            </button>
            <div className={cx('regions-map')}>
              <RegionsMap />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { RegionsDropdown }
