import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import { getFirstPartOfURLPath } from 'utils/helpers'
import { MultiSelect } from 'components/common/multi-select'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import styles from './GradeSelector.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as FuelGradesIcon } from 'images/performance-report/fuel-grades.svg'

const cx = classNames.bind(styles)

const GradeDropdown = ({ options, value, onValueChange, enableIcon, dataTestId }) => {
  const { t } = useTranslation()
  const items = options.slice()
  value = value.filter(v => items.includes(v))

  const [tooltipTarget, setTooltipTarget] = useState(null)

  useEffect(() => {
    if (tooltipTarget) {
      const timeout = setTimeout(() => {
        setTooltipTarget(null)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [tooltipTarget])

  const handleLessThanMinSelected = event => {
    setTooltipTarget(event.target)
  }

  const handleTooltipClick = () => {
    setTooltipTarget(null)
  }

  const handleDropdownClick = open => {
    !open &&
      amplitudeEvent(
        UserEvents.PerformanceReport.GRADES_FILTER_SELECTED(
          getFirstPartOfURLPath(window.location.pathname)
        )
      )
  }

  return (
    <>
      <MultiSelect
        placeholder={upperFirstOnly(
          t('widgets.performanceReport.noGradesSelected')
        )}
        allItemsText={upperFirstOnly(
          t('widgets.performanceReport.allGradesSelected')
        )}
        items={items}
        value={value}
        minSelected={1}
        onLessThanMinSelected={handleLessThanMinSelected}
        onValueSelect={onValueChange}
        leftAccessory={enableIcon && <FuelGradesIcon className={cx('icon')} />}
        onDropdownClick={handleDropdownClick}
        dataTestId={dataTestId}
      />
      <Overlay target={tooltipTarget} show={!!tooltipTarget} position="top">
        <Tooltip className={cx('tooltip')} onClick={handleTooltipClick}>
          <div className={cx('tooltip-content')} data-testid="tooltip">
            {t('widgets.performanceReport.atLeastOneGradeTooltip')}
          </div>
        </Tooltip>
      </Overlay>
    </>
  )
}

GradeDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onValueChange: PropTypes.func,
  enableIcon: PropTypes.bool,
  dataTestId: PropTypes.string,
}

GradeDropdown.defaultProps = {
  options: [],
  value: [],
  onValueChange: null,
  enableIcon: true,
}

export { GradeDropdown }
